'use strict';

export default class MenuSemaine {
  constructor(options) {
    console.log('menuSemaine module');

    const defaults = {
        galleryElem: '.menuSemaine__gallery',
        sliderClass: '.menuSemaine__images'
    };

    this.options = Object.assign({}, defaults, options);

    //auto init
    this._adjustGalleryCanvas()
        ._startGallerySlider();
  }


  _startGallerySlider() {
    $(this.options.sliderClass).slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000
    });

    return this;
  }

  _adjustGalleryCanvas() {
    $(this.options.galleryElem).css({height: $('.stablishmentInfo').outerHeight()});

    return this;
  }
}
