'use strict';

export default class Uri {
  constructor(options) {
    console.log('Uri component');

    const defaults = {};

    this.options = Object.assign({}, defaults, options);
    this.routes = [];
    this.mode = null;
    this.root = '/'

    this.adjustActiveLink = this._adjustActiveClassOnMenu;
  }

    config(options) {
        this.mode = options && options.mode && options.mode == 'history'
                    && !!(history.pushState) ? 'history' : 'hash';
        this.root = options && options.root ? '/' + this.clearSlashes(options.root) + '/' : '/';
        return this;
    }

    getFragment() {
        var fragment = '';
        if(this.mode === 'history') {
            fragment = this.clearSlashes(decodeURI(location.pathname + location.search));
            fragment = fragment.replace(/\?(.*)$/, '');
            fragment = this.root != '/' ? fragment.replace(this.root, '') : fragment;
        } else {
            var match = window.location.href.match(/#(.*)$/);
            fragment = match ? match[1] : '';
        }
        return this.clearSlashes(fragment);
    }

    clearSlashes(path) {
        return path.toString().replace(/\/$/, '').replace(/^\//, '');
    }

    add(re, handler) {
        if(typeof re == 'function') {
            handler = re;
            re = '';
        }
        this.routes.push({ re: re, handler: handler});
        return this;
    }

    remove(param) {
        for(var i=0, r; i<this.routes.length, r = this.routes[i]; i++) {
            if(r.handler === param || r.re.toString() === param.toString()) {
                this.routes.splice(i, 1);
                return this;
            }
        }
        return this;
    }

    flush() {
        this.routes = [];
        this.mode = null;
        this.root = '/';
        return this;
    }

    check(f) {
        var fragment = f || this.getFragment();
        for(var i=0; i<this.routes.length; i++) {
            var match = fragment.match(this.routes[i].re);
            if(match) {
                match.shift();
                this.routes[i].handler.apply({}, match);
                return this;
            }
        }
        return this;
    }

    listen() {
        var self = this;
        var current = self.getFragment();
        var fn = function() {
            if(current !== self.getFragment()) {
                current = self.getFragment();
                self.check(current);
            }
        }
        clearInterval(this.interval);
        this.interval = setInterval(fn, 50);
        return this;
    }

    navigate(path) {
        path = path ? path : '';
        if(this.mode === 'history') {
            history.pushState(null, null, this.root + this.clearSlashes(path));
        } else {
            window.location.href = window.location.href.replace(/#(.*)$/, '') + '#' + path;
        }
        return this;
    }

  _adjustActiveClassOnMenu (classToAnalise) {

    let url = window.location.href;
    $( "#"+classToAnalise+">.content>.menu>.leaf> a" ).each(function( index ) {
        let href = $(this).attr('href');
        if(href.indexOf(window.location.hostname) != -1) {
            let pieces_actualPage = window.location.pathname.split("/");
            for(let i = 0; i < pieces_actualPage.length ; i++) {
                if(pieces_actualPage[i] != ""){
                    if(href.indexOf(pieces_actualPage[i]) != -1) {
                        if
                        (
                            !(i == (pieces_actualPage.length-1) &&
                            typeof(href.indexOf(pieces_actualPage[i])) != "boolean" &&
                            !isNaN(href.indexOf(pieces_actualPage[i])))
                        )
                        {
                            $(this).addClass("active");
                        }
                    }
                }
            if(url == href){
                $(this).addClass("active");
            }

        }
    }});
  }

  matchHash(url, callback) {

    let hash = window.location.hash;

    if(hash == url) {
      if (typeof callback === 'function') {
        return callback(arguments);
      }
    } else {
      return false;
    }

  }

  hasHash(url, callback) {

    let hash = window.location.hash;
    let segments = hash.split('/').splice(1,5);
    let has = false;
    let segment;

    if(hash.toLowerCase().indexOf(url) >= 0) {
      if (typeof callback === 'function') {
        return callback(segments.pop());
      }
    } else {
      return false;
    }
  }

  matchSegment (name, callback) {

    let names = [].concat([], name);
    let pathname = window.location.pathname;

    //create an array with pathname with an limit of 100 segments
    //and remove last segment if is empty (caused by last "/" ex: /contact vs /contact/ )
    let segments = pathname.split('/').splice(1,100);

    if(segments.slice(0, -1) === '')
      segments.slice(0, -1);

    //if has no segments add an "/" to segments
    if (segments[0].length === 0)
      segments.push('/');

    for (let j = names.length - 1; j >= 0; j--) {
      for (let i = segments.length - 1; i >= 0; i--) {

        if(segments[i].toLowerCase() === names[j].toLowerCase()) {
          if (typeof callback === 'function') {
              return callback(arguments);
          }
        }
      }
    }
  }

  getSegments() {
    let url = window.location.href.replace(window.location.origin, '').split('/');
    return url.splice(1, url.length) ;
  }
}
