'use strict';

export default class SentenceSlider {
  constructor(options) {
    console.log('sentenceSlider module');

    const defaults = {
        sliderClass: '.sentenceSlider__list',
    };

    this.options = Object.assign({}, defaults, options);

    //auto init
    this._startGallerySlider();
  }


  _startGallerySlider() {

    if($(this.options.sliderClass).find('li').size() <= 1) return this;

    $(this.options.sliderClass).slick({
      infinite: true,
      dots: false,
      slidesToShow: 1,
      fade: true,
      slidesToScroll: 1,
      draggable: false,
      arrows: false,
      autoplaySpeed: 4000,
      accessibility: false
    });

    return this;
  }
}
