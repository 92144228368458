'use strict';

export default class AidesFinanceiresBlock {
  constructor(options) {
    console.log('aidesFinanceiresBlock module');

    const defaults = {
        galleryElem: '.aidesFinanceiresBlock__gallery',
        sliderClass: '.aidesFinanceiresBlock__images',
        accordionClass: '.aidesFinanceiresBlock__accordeon'
    };

    this.options = Object.assign({}, defaults, options);

    //auto init
    this._adjustGalleryCanvas()
        ._doAccordion()
        ._startGallerySlider();
  }

  _doAccordion() {

    let self = this;
    let myEvt = ('ontouchstart' in document.documentElement) ? 'touchend' : 'click';

    $(this.options.accordionClass).find('li').eq(0).addClass('is-open').find('.accordeon__text').show();

    $(this.options.accordionClass).find('li .accordeon__title').on(myEvt, function(evt) {
      let parent = $(this).parent();
      if(parent.hasClass('is-open')) {
        parent.removeClass('is-open').find('.accordeon__text').slideUp();
      } else {
        if($('body').outerWidth() >= 640) {
          $(self.options.accordionClass).find('li').removeClass('is-open').find('.accordeon__text').slideUp();
        }

        parent.addClass('is-open').find('.accordeon__text').slideDown();
      }

    });

    return this;

  }

  _startGallerySlider() {
    $(this.options.sliderClass).slick({
      infinite: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000
    });

    return this;
  }

  _adjustGalleryCanvas() {
    setInterval(function() {
      $(this.options.galleryElem).css({height: $('.stablishmentInfo').outerHeight()});
    }.bind(this),500);

    return this;
  }
}
