// Main javascript entry point
// Should handle bootstrapping/starting application

$ = jQuery.noConflict();

import Footer from '../_modules/footer/footer';
import Uri from '../_components/uri/uri';
import Template from '../_utils/template/templateEngine';
import Form from '../_components/form/form';
import TagManager from '../_modules/tagManager/tagManager';
import DemandeUrgenteBlock from '../_modules/demandeUrgenteBlock/demandeUrgenteBlock';
import StablishmentMap from '../_modules/stablishmentMap/stablishmentMap';
import PdATout from '../_modules/programmedAdminisionToutBlock/PdATout';
import VieSocialeBlock from '../_modules/_vieSocialeBlock/vieSocialeBlock';
import ConfortBlock from '../_modules/confortBlock/confortBlock';
import AidesFinanceiresBlock from '../_modules/aidesFinanceiresBlock/aidesFinanceiresBlock';
import SpacesClubBlock from '../_modules/spacesClubBlock/spacesClubBlock';
import VotreLogementBlock from '../_modules/votreLogementBlock/votreLogementBlock';
import AccompagnamentBlock from '../_modules/accompagnamentBlock/accompagnamentBlock';
import ServicesBlock from '../_modules/servicesBlock/servicesBlock';
import NearestBlock from '../_modules/nearestBlock/nearestBlock';
import MenuSemaine from '../_modules/menuSemaineBlock/menuSemaineBlock';
import MiniSlider from '../_modules/miniSlider/miniSlider';
import TestimonialBlock from '../_modules/testimonialBlock/testimonialBlock';
import HomeBlock from '../_modules/homeBlock/homeBlock';
import SentenceSlider from '../_modules/sentenceSlider/sentenceSlider';
import CancelZoom from '../_utils/cancelZoom/cancelZoom';
import Cookie from '../_utils/cookie/cookie';
import CookieValidation from '../_modules/cookieValidationBanner/cookieValidationBanner';
import ImagesLightbox from '../_modules/imagesLightbox/imagesLightbox';
import Icons from '../_modules/icons/icons';

'use strict';

$( window ).load(function(evt) {

  const cookie =  new Cookie();
  const cookieValidation = new CookieValidation({cookie: cookie});

});

$(() => {

  const footer = new Footer();
  const uri = new Uri();
  const form = new Form();
  const tagManager = new TagManager();
  const demandeUrgenteBlock = new DemandeUrgenteBlock();
  const stablishmentMap = new StablishmentMap();
  const pdATout = new PdATout();
  const vieSocialeBlock = new VieSocialeBlock();
  const confortBlock = new ConfortBlock();
  const aidesFinanceiresBlock = new AidesFinanceiresBlock();
  const spacesClubBlock = new SpacesClubBlock();
  const votreLogementBlock = new VotreLogementBlock();
  const accompagnamentBlock = new AccompagnamentBlock();
  const servicesBlock = new ServicesBlock();
  const nearestBlock = new NearestBlock();
  const menuSemaine = new MenuSemaine();
  const miniSlider = new MiniSlider();
  const testimonialBlock = new TestimonialBlock();
  const homeBlock = new HomeBlock();
  const sentenceSlider = new SentenceSlider();
  const cancelZoom = new CancelZoom();
  const imagesLightbox = new ImagesLightbox();
  const icons = new Icons();

  footer.listemSiteSwitch();

  // uri.adjustActiveLink("block-menu-menu-dvp-7-menu-1");

  ///////////////////////////////
  /////Third party configs///////
  ///////////////////////////////
  $(document).on(('ontouchstart' in document.documentElement) ? 'touchend' : 'click', '.fancybox-button--close', function(evt) {
    cancelZoom._resetMeta();
  });

  $("[data-fancy]").on('click', function(evt) {

    var src = $(this).attr('data-src');
    var type = $(this).attr('data-type');

    cancelZoom._disableZoom();

    $.fancybox.open({
      src  : src,
      type : type,
      opts : {
        afterLoad: function () {
          $(".fancybox-toolbar").prependTo(".fancybox-content");

          let iframeContentHeight = $('.fancybox-iframe').contents().find('.page').outerHeight() + 20;
          $('.fancybox-content').css('height', iframeContentHeight + 'px');
        }
      }
    });
  });

  $( ".videoBlock__button" ).fancybox({
    type: 'iframe'
  });

});
