'use strict';

export default class MiniSlider {
  constructor(options) {
    console.log('miniSlider module');

    const defaults = {
        sliderClass: '.miniSlider__list'
    };

    this.options = Object.assign({}, defaults, options);

    //autoinit
    this._setup();
  }

  _setup() {

    if($(this.options.sliderClass).find('li').size() <= 1) return this;

    $(this.options.sliderClass).slick({
      infinite: true,
      arrows: true,
      slidesToShow: 1,
      dots: false,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000
    });

    return this;

  }
}
