'use strict';

export default class Footer {
  constructor(options) {
    console.log('Footer module');

    const defaults = {
    	sitesSwitchElem: '.footer__select'
    };

    this.options = Object.assign({}, defaults, options);

    //exposed methods
    this.listemSiteSwitch = this._listemSiteSwitch;
    this._adjustModalLinks();
  }

  _listemSiteSwitch () {

    $(this.options.sitesSwitchElem).on('change', function (evt) {
      let selected = $(this).find(":selected").val();

      if(selected !== '')
        window.open(selected, '_blank');
    });

  	return this;
  }

  _adjustModalLinks() {
    $('.footer__img + .block-menu a').last()
    .attr('data-fancy', 'iframe')
    .attr('data-type', 'iframe')
    .attr('data-src', $('.header__body').eq(0).attr('data-establishmentID'))
    .removeAttr('href')
    .css({cursor: 'pointer'})
  }
}
