'use strict';

export default class HomeBlock {
  constructor(options) {
    console.log('homeBlock module');

    const defaults = {
        galleryElem: '.homeBlock__gallery',
        sliderClass: '.homeBlock__images',
        sentenceSliderList: '.sentenceSlider__list',
        iconsList: '.homeBlock__icons ul li',
        sliderVideoClass: '.homeBlock_video'
    };

    this.options = Object.assign({}, defaults, options);

    //auto init
    this._adjustGalleryCanvas()
        ._startGallerySlider()
        ._startVideoSlider();

  }

  _adjustIconsAlt() {

    $.each($(this.options.iconsList), function(i, elem) {
      $(elem).attr('title', $(elem).text());
    });

    return this;
  }

  _startGallerySlider() {

    if($(this.options.sliderClass).find('li').size() <= 1) return this;

    let options = {
      infinite: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false,
      accessibility: false
    };

    if ($(this.options.sentenceSliderList).length > 0) {
      options.asNavFor = $(this.options.sentenceSliderList);
    }

    $(this.options.sliderClass).slick(options);
    return this;
  }

  _adjustGalleryCanvas() {
    setInterval(function() {
      $(this.options.galleryElem).css({height: $('.stablishmentInfo').outerHeight()});
    }.bind(this),500);

    return this;
  }


  _startVideoSlider() {

    if($(this.options.sliderVideoClass).find('iframe').size() <= 1) return this;

    let options = {
      infinite: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      accessibility: false
    };
    if ($(this.options.sentenceSliderList).length > 0) {
      options.asNavFor = $(this.options.sentenceSliderList);
    }

$(this.options.sliderVideoClass).slick(options);

    return this;
  }

}
