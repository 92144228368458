'use strict';

export default class StablishmentMap {
  constructor(options) {
    console.log('stablishmentMap module');

    const defaults = {
        mapElement: '#map'
    };
    this.options = Object.assign({}, defaults, options);

    this.map_options = {
        mapTypeId: google.maps.MapTypeId.ROADMAP //set map type to road map
    };

    //auto init
    this._getLatLng()
        ._adjustMapCanvas()
        ._drawMap();

  }

  _getLatLng() {

    this.myLatLng = {lat: $(this.options.mapElement).data('lat') * 1, lng: $(this.options.mapElement).data('lng')  * 1};
    return this;
  }

  _adjustMapCanvas() {
    setInterval(function(){
      $(this.options.mapElement).css({height: $('.stablishmentInfo').outerHeight()});
    }.bind(this), 500)

    return this;
  }

  _drawMap() {

    if(this.myLatLng.lat == 0 && this.myLatLng.lng == 0) return;

    let label = $(this.options.mapElement).data('label').toString().toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase());

    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 14,
      center: this.myLatLng,
      disableDefaultUI: true
    });

    let markerIcon = {
      url: (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) ? 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png' : window.location.origin + '/sites/all/themes/salsevi/images/map_marker.png',
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(label.split('').length / 2 * 7.6 + 40,20)
    };

    this.marker = new google.maps.Marker({
      position: this.myLatLng,
      label: {
        text: label,
        color: "#d50d41"
      },
      map: this.map,
      labelClass: 'map__legend',
      icon: markerIcon
    })

  }
}
