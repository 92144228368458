'use strict';

export default class TestimonialBlock {
  constructor(options) {
    console.log('TestimonialBlock module');

    const defaults = {
        sliderClass: '.testimonialBlock__list'
    };

    this.options = Object.assign({}, defaults, options);

    //exposed methods
    this.startSlider = this._startSlider;

    //auto init
    this._startSlider();
  }


  _startSlider () {

    $(this.options.sliderClass).slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

  }
}
