'use strict';

export default class Cookie {
  constructor(options) {
    console.log('Cookie module');

    const defaults = {
        prefix: 'dvi_'
    };

    this.options = Object.assign({}, defaults, options);

  }

  generateUUID() {

    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;

  }

  generateUUIDInteger() {

    let iUid = new Date().getTime();
    iUid = iUid & 0xffffffff;

    return iUid;

  }

  createCookie (name, value, days) {

    if(this.getCookie(name)) return;

    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = this.options.prefix + name + "=" + value + expires + "; path=/";
  }

  getCookie(name) {
    let c_start;
    let c_end;
    if (document.cookie.length > 0) {
      c_start = document.cookie.indexOf(this.options.prefix + name + "=");
      if (c_start != -1) {
          c_start = c_start + (this.options.prefix + name).length + 1;
          c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) {
          c_end = document.cookie.length;
        }
          return unescape(document.cookie.substring(c_start, c_end));
      }
    }

    return false;
  }
}