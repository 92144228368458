'use strict';

export default class AccompagnamentBlock {
  constructor(options) {
    console.log('accompagnamentBlock module');

    const defaults = {
        galleryElem: '.accompagnamentBlock__gallery',
        sliderClass: '.accompagnamentBlock__images',
        iconsList: '.accompagnamentBlock__icons ul li'
    };

    this.options = Object.assign({}, defaults, options);

    //auto init
    this._adjustGalleryCanvas()
        ._startGallerySlider();
  }

  _adjustIconsAlt() {

    $.each($(this.options.iconsList), function(i, elem) {
      $(elem).attr('title', $(elem).text());
    });

    return this;
  }

  _startGallerySlider() {

    if($(this.options.sliderClass).find('li').size() <= 1) return this;

    $(this.options.sliderClass).slick({
      infinite: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000
    });

    return this;
  }

  _adjustGalleryCanvas() {
    setInterval(function() {
      $(this.options.galleryElem).css({height: $('.stablishmentInfo').outerHeight()});
    }.bind(this),500);

    return this;
  }
}
