'use strict';

export default class DemandeUrgenteBlock {
  constructor(options) {
    console.log('demandeUrgenteBlock module');

     const defaults = {
        form: '.demandeUrgenteBlock__form'
    };

    this.options = Object.assign({}, defaults, options);

    this._messagesAdjust();
  }

  _messagesAdjust() {

    if($(".demandeUrgenteBlock__form").html()){
        $(".page__messages").css("display","none");
    }

    if( document.getElementById('messages')){
      var msg = document.getElementById('messages').innerHTML;
      if( document.getElementById('messages_demande_urgente')){
        document.getElementById('messages_demande_urgente').innerHTML = msg;
        $(".demandeUrgenteBlock .page__messages").css("display","block");

        setTimeout(function() {

          let targetScroll = $(".demandeUrgenteBlock__form").offset().top - $(".demandeUrgenteBlock__form").height();

          if($('body').outerWidth() <= 640) {
            window.scrollTo(0, $(".demandeUrgenteBlock__form").offset().top - 320);
          } else {
            $("html, body").animate( { scrollTop : targetScroll - 100 }, 300 );
          }
        }, 400);


      }
    }
  }
}
