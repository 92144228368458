'use strict';

export default class TagManager {
  constructor(options) {
    console.log('TagManager module');

    const defaults = {};

    this.options = Object.assign({}, defaults, options);

    this.residenceNameOrHome = '';

    //auto init
    this._getResidenceName();
    this._attachEventsToVideo360();
    this._attachEventsToVideo();
    this._attachEventsToTemoignage();
    this._attachEventsToTelechargement();
    this._attachEventsToReseauxSociaux();
    this._attachEventsToImpressionFiche();
    this._attachEventsToDossierDadmission();
    this._attachEventsToSiteDeLaResidence();
    this._attachEventsToPriseDeContact();
    this._attachEventsToConseilPhone();
    this._attachEventsToConseilEtreRappele();
    this._attachEventsToConseilNousContacter();
    this._attachEventsToRendezVous();
    this._attachEventsToGeolocalisation();
    this._attachEventsToSortie();
    this._attachPushClickEvents();
    this._attachPushDisplayPageEvents();
    this._attachPushDisplayEvents();
    this._attachContactMailEvents();
    this._attachEventsToSubmitButtons();

    this._attachEventsToFormulaireAdmission();
    this._attachEventsToJournalResidence();
    this._attachEventsToDemandeUrgente();

  }

  _getResidenceName() {

    let config = {
        stablishmentNameElem: '.stablishmentInfo__title'
    };

    if($(config.stablishmentNameElem).size()) {
      this.residenceNameOrHome = $.trim($(config.stablishmentNameElem).text());
    } else {
      this.residenceNameOrHome = 'homepage';
    }

    return this.residenceNameOrHome;

  }

  _pushClickEvt(action, name, category) {

    console.log("evt trigger click:", [action, name, category]);

    dataLayer.push({
        event: 'click',

        // Evénement
        'event_action': action, // Event type of action
        'event_name': name, // Event name
        'event_category': category, // Event category
    });

  }

  _pushViewEvt(page) {

    console.log("evt pageview ", [page]);

    dataLayer.push({
        hitType: 'pageview',
        page: page || 'unknown page'
    });

  }

  _pushPageEvt(pageName, pageCategory) {

    console.log("evt trigger page:", [pageName, pageCategory]);

    dataLayer.push({
        event: 'page',

        // Evénement
        'page_name': pageName, // Event type of action
        'page_category': pageCategory, // Event name
    });

  }

  _attachContactMailEvents() {
    let config = {
        evtTriggerElem: ".header a[href*='contact'], .footer a[href*='contact'] "
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom document,  evt category
        this._pushClickEvt('email', this.residenceNameOrHome, 'contact');

    }.bind(this));

  }

  _attachPushDisplayPageEvents() {
    if($('body').hasClass('page-node') && !$('body').hasClass('node-type-establishment')) {
        this._pushPageEvt(window.location.pathname, 'node');

                        //evt action,   nom residence,  evt category
        this._pushClickEvt('display', $.trim($('body').find('h1').eq(0).text()), 'push');
    }
  }

  _attachPushDisplayEvents() {

    let config = {
        evtTriggerElemDroiteDisplay: ".serviceAleatoireBlock .slick-next, .infoBlock .slick-next",
        evtTriggerElemGauceDisplay: ".serviceAleatoireBlock .slick-prev, .infoBlock .slick-prev",
        evtTriggerClick: '.serviceAleatoireBlock__title, .infoBlock__title, .infoBlock__button'
    };

    let that = this

    function getTitle(ctx) {
        let elem = $(ctx).closest('.serviceAleatoireBlock, .infoBlock');
        let title = "unknown title";

        title =  $.trim(elem.find('.slick-current').find(config.evtTriggerClick).find('a, span').eq(0).text());

        return title;
    }


    function doEvt(parent) {

        $(document).on('click touchend', config.evtTriggerElemDroiteDisplay, function(evt) {
                            //evt action,   nom residence,  evt category
            parent._pushClickEvt('display', getTitle(this), 'push');

        });

        $(document).on('click touchend', config.evtTriggerElemGauceDisplay, function(evt) {
                            //evt action,   nom residence,  evt category
            parent._pushClickEvt('display', getTitle(this), 'push');

        });

        $(document).on('click touchend', config.evtTriggerClick, function(evt) {
            if(!$(this).is('.infoBlock__title')) {
                                //evt action,   nom residence,  evt category
                parent._pushClickEvt('click', getTitle(this), 'push');
            }

        });
    }

    var hasConfigItensInterval = setInterval(function() {
        if($(config.evtTriggerElemDroiteDisplay).size() > 0) {

            doEvt(this);
            clearInterval(hasConfigItensInterval);
        }
    }.bind(this), 100);


    return this;
  }

  _attachPushClickEvents() {
    let config = {
        evtTriggerElem: [
        '.newsBlock .newsBlock__call',
        '.newsBlock .newsBlock__title a',
        '.newsBlock .newsBlock__image a',
        '.detailsMrBlock .detailsMrBlock__button',
        '.detailsAdBlock .detailsAdBlock__button',
        '.detailsRsBlock .detailsRsBlock__button',
        '.cta__content a',
        '.aideDomicileBlock__button',
        '.aideDomicileBlock__title a',
        '.newsBlock--sticky .field-content a',
        '.newsBlock--regular .field-content a'
        ]
    };

    let that = this;

    function getTitle(ctx) {
        let elem = $(ctx);
        let title = "unknown title";

        if(elem.closest('.infoBlock--item').size()) {
            title = $.trim(elem.closest('.infoBlock--item').find('.infoBlock__title .field-content').text());
            return title;
        }

        if(elem.closest('.newsBlock').size()) {
            title = $.trim(elem.closest('.views-row').find('.newsBlock__title a').text());
            return title;
        }

        if(elem.closest('.newsBlock--sticky').size()) {
            title = $.trim(elem.closest('.views-row').find('.newsBlock__title a').text());
            return title;
        }

        if(elem.closest('.newsBlock--regular').size()) {
            title = $.trim(elem.closest('.views-row').find('.newsBlock__title a').text());
            return title;
        }

        if(elem.closest('.newsBlock__title').size()) {
            title = $.trim(elem.closest('.newsBlock__title').find('a').text());
            return title;
        }

        if(elem.closest('.aideDomicileBlock--item').size()) {
            title = $.trim(elem.closest('.aideDomicileBlock--item').find('.aideDomicileBlock__title a').text());
            return title;
        }

        if(elem.hasClass('detailsMrBlock__button') || elem.hasClass('detailsAdBlock__button') || elem.hasClass('detailsRsBlock__button')) {
            title = $.trim(elem.text());
            return title;
        }

        if(elem.closest('.cta__content').size()) {
            title = $.trim(elem.closest('.cta__content').find('a p').text());
            return title;
        }

    }

    $(document).on('click touchend', config.evtTriggerElem.join(','), function(evt) {
                        //evt action,   nom residence,  evt category
        that._pushClickEvt('click', getTitle(this), 'push');

    });
  }

  _attachEventsToVideo360() {

    let config = {
        evtTriggerElem: ".experience360Block__button",
        evtTriggerEndElem: ".fancybox-button.fancybox-button--close"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('play', '', 'visite_360');

    }.bind(this));

    $(document).on('click touchend', config.evtTriggerEndElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('stop', '', 'visite_360');

    }.bind(this));

  }

  _attachEventsToVideo() {

    let config = {
        evtTriggerElem: ".grandmaBlock__button, .videoBlock__button",
        evtTriggerEndElem: ".fancybox-button.fancybox-button--close"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('play', '', 'video');

    }.bind(this));

    $(document).on('click touchend', config.evtTriggerEndElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('stop', '', 'video');

    }.bind(this));

  }

  _attachEventsToSubmitButtons() {

    let config = {
        evtTriggerElem: "form"
    };

    if($('.messages.status').size()) {
        this._pushViewEvt(location.pathname);

    }

  }

  _attachEventsToTelechargement() {

    let config = {
        evtTriggerElem: ".stablishmentInfo__options .stablishment__telecharger"
    };

    let that = this;

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom document,  evt category
        that._pushClickEvt('brochure', $(this).attr('href').split('/').slice(-1)[0], 'telechargement');

    });

  }

  _attachEventsToFormulaireAdmission() {

    let config = {
        evtTriggerElem: ".documentsBlock .documentsBlock__formulaireAdmission"
    };

    let that = this;

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom document,  evt category
        that._pushClickEvt('telechargement-formulaire-admission', 'formulaire-admission', 'telechargement');

    });

  }

  _attachEventsToJournalResidence() {

    let config = {
        evtTriggerElem: ".documentsBlock .documentsBlock__journalResidence"
    };

    let that = this;

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom document,  evt category
        that._pushClickEvt('telechargement-journal-residence', 'journal-residence', 'telechargement');

    });

  }

  _attachEventsToGeolocalisation() {

    let config = {
        evtTriggerElem: ".departementBlock__button"
    };

    let values = {
        selectDepartement: '.departementBlock__form select:eq(0)',
        selectService: '.departementBlock__form select:eq(1)'
    };


    $(document).on('click touchend', config.evtTriggerElem, function(evt) {

        let departement = $(values.selectDepartement).val();
        let type = $(values.selectService).val();

                        //evt action,   nom residence,  evt category
        this._pushClickEvt(departement, type, 'geolocalisation');

    }.bind(this));

    return this;

  }

  _attachEventsToSortie() {

    let config = {
        evtTriggerElem: "a[href^='http://'], a[href^='https://'], a[href^='/'], a[href^='node']",
        evtExcludeElem: [
            ".stablishmentInfo__options .stablishment__telecharger",
            "a[href*='/maisons-de-retraite']",
            "a[href*='/residence-seniors']",
            "a[href*='/aide-a-domicile']"]
    };

    let that = this;

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
        // evt.preventDefault();

        let attr = $(this).attr('data-fancybox');

        if (typeof attr !== typeof undefined && attr !== false) {
            return false;
        }

        if(!$(evt.target).is(config.evtExcludeElem.join(','))){

            let link = $(this).attr('href');

            //"interne" ou "externe"
            //check if is an external or internal link
            if(link.indexOf(window.location.origin) !== -1 || link.search(/^(\w+:)?\/\//) == -1) {
                //is internal
                //              evt action,   nom residence,  evt category
                that._pushClickEvt('interne', link , 'sortie');
            } else {
                //is external
                that._pushClickEvt('externe', link, 'sortie');
            }
        }

    });

    return this;

  }

  _attachEventsToReseauxSociaux() {

    let config = {
        evtTriggerElem: ".footer__social .menu-item-twitter"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('twitter', $(config.evtTriggerElem).attr('href'), 'reseaux_sociaux');

    }.bind(this));

    return this;

  }

  _attachEventsToTemoignage() {

    let config = {
        evtTriggerElemDroite: ".testimonialBlock .slick-next",
        evtTriggerElemGauce: ".testimonialBlock .slick-prev"
    };

    function doEvt(parent) {

        $(document).on('click touchend', config.evtTriggerElemDroite, function(evt) {
                            //evt action,   nom residence,  evt category
            parent._pushClickEvt('droite', '', 'temoignage');

        });

        $(document).on('click touchend', config.evtTriggerElemGauce, function(evt) {
                            //evt action,   nom residence,  evt category
            parent._pushClickEvt('gauche', '', 'temoignage');

        });
    }

    var hasConfigItensInterval =  setInterval(function() {
        if($(config.evtTriggerElemDroite).size() > 0) {

            doEvt(this);
            clearInterval(hasConfigItensInterval);
        }
    }.bind(this), 100);

    return this;

  }

  _attachEventsToImpressionFiche() {

    let config = {
        evtTriggerElem: ".stablishment__print"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('fiche', this.residenceNameOrHome, 'impression');

    }.bind(this));

    return this;

  }

  _attachEventsToDossierDadmission() {

    let config = {
        evtTriggerElem: ".stablishment__dossierdadmission"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('dossier', this.residenceNameOrHome, 'impression');

    }.bind(this));

    return this;

  }

  _attachEventsToSiteDeLaResidence() {

    let config = {
        evtTriggerElem: ".stablishment__siteResidence"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('site_residence', this.residenceNameOrHome, 'sortie');

    }.bind(this));

    return this;

  }

  _attachEventsToPriseDeContact() {

    let config = {
        evtTriggerElem: ".stablishmentInfo__button[data-src*='/contact-residence/form/']"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('contacter_residence', this.residenceNameOrHome, 'contact');

    }.bind(this));

    return this;

  }

  _attachEventsToRendezVous() {

    let config = {
        evtTriggerElem: ".stablishmentInfo__button[data-src*='/prendre-un-rdv/form/']"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('prendre_rendezvous', this.residenceNameOrHome, 'contact');

    }.bind(this));

    return this;

  }

  _attachEventsToConseilPhone() {

    let config = {
        evtTriggerElem: ".flag__link"
    };

    $(document).on('click touchend', config.evtTriggerElem, function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('rappel_immediat', this.residenceNameOrHome, 'contact');

    }.bind(this));

    return this;

  }

  _attachEventsToConseilEtreRappele() {

    let config = {
        evtTriggerElem: ".conseil__link[data-src='/etre-rappele/form']"
    };

    $(document).on('click touchend', config.evtTriggerElem , function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('etre_rappele', this.residenceNameOrHome, 'contact');

    }.bind(this));

    return this;
  }

  _attachEventsToConseilNousContacter() {

    let config = {
        evtTriggerElem: ".conseil__link[data-src='/nous-contacter/form']"
    };

    $(document).on('click touchend', config.evtTriggerElem , function(evt) {
                        //evt action,   nom residence,  evt category
        this._pushClickEvt('nous_contacter', this.residenceNameOrHome, 'contact');

    }.bind(this));

    return this;

  }

  _attachEventsToDemandeUrgente() {

    let config = {
        evtTriggerElem: ".demandeUrgenteBlock"
    };

    let that = this;

    let interval = setInterval(function(){

        if($(config.evtTriggerElem).find('.messages.status').size()){
            that._pushViewEvt('/demande-urgente/form/confirmation');
            clearInterval(interval);
        }

    }, 3000);

    return this;

  }

  

}
