'use strict';

export default class NearestBlock {
  constructor(options) {
    console.log('NearestBlock module');

    const defaults = {
        sliderClass: '.nearestBlock__gallery',
        tabs: {
          list: '.nearestBlock__tabs .tabs__list',
          content: '.nearestBlock__tabs .tabs__content'
        }
    };

    this.options = Object.assign({}, defaults, options);

    //auto init
    this._startGallerySlider()
        ._doTabs();
  }

  _startGallerySlider() {
    $(this.options.sliderClass).slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    return this;
  }

  _reinitGallerySlider() {
    $(this.options.sliderClass).slick('refresh');
    return this;
  }

  _doTabs() {

    let self = this;
    $(self.options.tabs.content).find('> li').hide().eq(0).show();

    $(self.options.tabs.list).find('> li').on('click touchend', function(evt) {
      let $this = $(this);
      let index = $this.index();

      $(self.options.tabs.list).find('> li').removeClass('is-active').eq(index).addClass('is-active');

      $(self.options.tabs.content).find('> li').hide().eq(index).show();
      self._reinitGallerySlider();
    });

    return this;

  }
}
