'use strict';

export default class CookieValidation {
  constructor(options){
    console.log('CookieValidation module');

    const defaults = {
      cookie: false
    };

    this.options = Object.assign({}, defaults, options);

    this.cookieVerification(); 

  }

  cookieVerification(){
    let haveCookie = this.options.cookie.getCookie('accepted');

    let cookieBanner = document.querySelector('.cookieValidation');

    console.log(`Cookie has been ${haveCookie}`);

    if(haveCookie == false){
      cookieBanner.style.display = 'block';
      this.cookieOkButton(cookieBanner);
    } else {
      cookieBanner.style.display = 'none';
    }
  }

  cookieOkButton(container){
    let okButton = document.querySelector('#cookieValidationOk');

    okButton.addEventListener('click', function(){

      this.options.cookie.createCookie('accepted', 'accepted', 365);

      container.style.display = 'none';

    }.bind(this));
  }

}