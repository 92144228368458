'use strict';

export default class PdATout {
  constructor(options) {
    console.log('PdATout module');

    const defaults = {
        galleryElem: '.PdATout__gallery',
        sliderClass: '.PdATout__images',
        tabs: {
          list: '.PdATout__tabs',
          content: '.PdATout__contents'
        }
    };

    this.options = Object.assign({}, defaults, options);

    //auto init
    this._adjustGalleryCanvas()
        ._startGallerySlider();

    this._doTabs();
  }


  _startGallerySlider() {
    $(this.options.sliderClass).slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    return this;
  }

  _adjustGalleryCanvas() {
    setInterval(function() {
      $(this.options.galleryElem).css({height: $('.stablishmentInfo').outerHeight()});
    }.bind(this),500);

    return this;
  }

  _doTabs() {

    let self = this;

    $(self.options.tabs.list).find('> li').on('click touchend', function(evt) {
      let $this = $(this);
      let index = $this.index();

      $(self.options.tabs.list).find('> li').removeClass('is-active').eq(index).addClass('is-active');

      $(self.options.tabs.content).find('> li').hide().eq(index).show();
    });

    return this;

  }
}
