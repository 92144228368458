'use strict';

export default class Form {
  constructor(options) {
    console.log('Form module');

    const defaults = {
      formElem: "form"

    }

    this.options = Object.assign({}, defaults, options);

    this._maxLength();
    this._addLoadingFallback();
  }

  _addLoadingFallback() {

    $(this.options.formElem).on('submit', function(evt){
      $(this).addClass('is-loading');
    });

  }

  _maxLength(){
    var inputs = $('input, textarea');
  inputs.keyup(function(e){
    if($(this).attr('maxLength')){
      if($(this).val().length > $(this).attr('maxLength')){
        $(this).val($(this).val().substr(0, $(this).attr('maxLength')));
      }
    }
  });
  }
}
