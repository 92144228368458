'use strict';

import 'lightgallery.js';
import 'lg-thumbnail.js';
import 'lg-fullscreen.js';
import 'lg-zoom.js';

export default class ImagesLightbox {
  constructor(options) {
    console.log('ImagesLightbox module');

    lightGallery(document.getElementById('lightgallery'), {
      thumbnail:true
    });

  }
}
