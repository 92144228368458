'use strict';

export default class Icons {
  constructor() {
    console.log('Icons module');

    this.targets = $('[rel~=tooltip]');
    this.target  = false;
    this.tooltip = false;
    this.title   = false;
    this.tip   = false;

    this._icons();
  }

  _icons() {
    this.targets.bind( 'mouseenter', function(e) {
      this.target  = $(e.currentTarget);
      this.tip = this.target.attr('title');
      this.tooltip = $('<div id="tooltip"></div>');

      if(!this.tip || this.tip === '')
        return false;

      this.target.removeAttr('title');
      this.tooltip.css('opacity', 0)
        .html(this.tip)
        .appendTo('body');

      this.init_tooltip();
      $(window).resize(this.init_tooltip());

      this.target.bind('mouseleave', this.remove_tooltip.bind(this));
      this.tooltip.bind('click', this.remove_tooltip.bind(this));
    }.bind(this));
  }

  init_tooltip() {
    if($(window).width() < this.tooltip.outerWidth() * 1.5 )
      this.tooltip.css('max-width', $( window ).width() / 2);
    else
      this.tooltip.css('max-width', 340);

    var pos_left = this.target.offset().left + (this.target.outerWidth() / 2) - (this.tooltip.outerWidth() / 2),
      pos_top  = this.target.offset().top - this.tooltip.outerHeight() - 20;

    if(pos_left < 0) {
      pos_left = this.target.offset().left + this.target.outerWidth() / 2 - 20;
      this.tooltip.addClass('left');
    }
    else
      this.tooltip.removeClass( 'left' );

    if(pos_left + this.tooltip.outerWidth() > $(window).width()) {
      pos_left = this.target.offset().left - this.tooltip.outerWidth() + this.target.outerWidth() / 2 + 20;
      this.tooltip.addClass( 'right' );
    }
    else
      this.tooltip.removeClass( 'right' );

    if(pos_top < 0) {
      //var pos_top  = this.target.offset().top + this.target.outerHeight();
      this.tooltip.addClass('top');
    }
    else
      this.tooltip.removeClass('top');

    this.tooltip.css( { left: pos_left, top: pos_top } )
      .animate( { top: '+=10', opacity: 1 }, 50 );
  };

  remove_tooltip() {
    this.tooltip.animate({ top: '-=10', opacity: 0}, 50, function() {
      $(this).remove();
    });

    this.target.attr( 'title', this.tip );
  };
}
